import { IsBoolean, IsOptional, IsString } from 'class-validator'

export class SaveInterventionsAnomalies {
  @IsOptional()
  @IsBoolean()
  isResolved?: boolean

  @IsOptional()
  @IsString()
  note?: string
}
