<div
  role="tablist"
  class="tabs"
  [class.tabs-boxed]="style === 'boxed'"
  [class.tabs-lifted]="style === 'lifted'"
  [class.tabs-bordered]="style === 'bordered'"
  [class.tab-lg]="size === 'lg'"
>
  <span
    *ngFor="let tab of tabs"
    role="tab"
    class="tab"
    [class.tab-active]="selectedTab === tab.value"
    [class.text-base]="size === 'lg'"
    [class.font-semibold]="selectedTab === tab.value"
    (click)="selectTab(tab)"
  >
    <img *ngIf="tab.icon" src="{{ tab.icon }}" class="mr-2" style="width: 20px; height: 20px" />
    {{ tab.label | translate }}
  </span>
</div>
