import { Injectable } from '@angular/core'
import { environment } from '@front-app-environments/environment'
import { NetworkingService } from '@agropilot/app/core/networking/services/networking.service'
import { Observable } from 'rxjs'
import { Region } from '@agroone/entities'

@Injectable({
  providedIn: 'root',
})
export class RegionNetworkService {
  constructor(private network: NetworkingService) {}

  get(): Observable<Region | Region[]> {
    return this.network.get(`${environment.apiUrl}${environment.regions}`)
  }
}
