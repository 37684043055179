import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  ContainsFilter,
  EqualityFilter,
  ExistsFilter,
  Filter,
  InferiorityFilter,
  NotExistsFilter,
  Parameters,
  RangeFilter,
  Request,
  StartsWithFilter,
  SuperiorityFilter,
} from '../models/networking'
import { combineLatest, firstValueFrom, lastValueFrom, map, Observable, of, switchMap } from 'rxjs'
import { MsSqlPaginatedData } from '@agroone/entities'

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private filtersFn: {
    [key in Filter['operator']]: (filter: Filter) => string
  } = {
    equality: (filter: EqualityFilter<any>) => `${filter.fieldName}=${this.value(filter.value)}`,
    inferiority: (filter: InferiorityFilter<any>) => {
      const operator: string = filter.isStrict ? '<' : '<='
      return `${filter.fieldName}${operator}${this.value(filter.value)}`
    },
    superiority: (filter: SuperiorityFilter<any>) => {
      const operator: string = filter.isStrict ? '>' : '>='
      return `${filter.fieldName}${operator}${this.value(filter.value)}`
    },
    contains: (filter: ContainsFilter<any>) => `${filter.fieldName} CONTAINS ${this.value(filter.value)}`,
    in: (filter: ContainsFilter<any>) => `${filter.fieldName} IN ${this.value(filter.value)}`,
    startswith: (filter: StartsWithFilter<any>) => `${filter.fieldName} STARTSWITH ${this.value(filter.value)}`,
    range: (filter: RangeFilter<any>) =>
      `${filter.fieldName} BETWEEN ${this.value(filter.min)} AND ${this.value(filter.max)}`,
    exists: (filter: ExistsFilter) => `${filter.fieldName} EXISTS`,
    notexists: (filter: NotExistsFilter) => `${filter.fieldName} NOTEXISTS`,
  }

  constructor(private http: HttpClient) {}

  public getHttpParams(params: Parameters): {
    [key: string]: string | number | boolean
  } {
    const httpParams: { [key: string]: string | number | boolean } = {}

    if (params.pageLength) {
      httpParams.pageLength = params.pageLength
    }

    if (params.page) {
      httpParams.page = params.page
    }

    if (params.noPagination) {
      httpParams.noPagination = params.noPagination
    }

    if (params.filters && Object.values(params.filters)?.length) {
      const filter: string = Object.values(params.filters)
        .map((f) => this.filtersFn[f.operator](f))
        .join(',')
      httpParams.filter = filter
    }

    if (params.sortBy && params.sortOrder) {
      httpParams.sortBy = params.sortBy
      httpParams.sortOrder = params.sortOrder
    }

    if (params.otherParams) {
      Object.keys(params.otherParams).forEach((key) => {
        httpParams[key] = params.otherParams[key]
      })
    }

    return httpParams
  }

  public async getPaginated<T>(
    url: string = '/',
    config?: {
      headers?: {
        [header: string]: string | string[]
      }
      params?: {
        [param: string]: string | string[]
      }
    }
  ): Promise<MsSqlPaginatedData<T>> {
    const response: MsSqlPaginatedData<T> = await firstValueFrom(this.http.get<MsSqlPaginatedData<T>>(url, config))
    return response
  }

  public async getAllFromPaginated<T>(
    url: string = '/',
    config?: {
      headers?: {
        [header: string]: string | string[]
      }
      params?: {
        [key: string]: string | string[] | number | boolean
      }
    }
  ): Promise<T[]> {
    const request: Observable<T[]> = this.http
      .get<MsSqlPaginatedData<T>>(url, {
        headers: config?.headers,
        params: {
          ...(config?.params ?? {}),
          page: 1,
        },
      })
      .pipe(
        switchMap((response: MsSqlPaginatedData<T>) => {
          if (response.currentPage < response.pageCount) {
            return combineLatest(
              Array.from({ length: response.pageCount - 1 }, (_, i) =>
                this.http.get<MsSqlPaginatedData<T>>(url, {
                  headers: config?.headers,
                  params: {
                    ...(config?.params ?? {}),
                    page: i + 2,
                  },
                })
              )
            ).pipe(
              map((v) => {
                v.unshift(response)
                return v
              })
            )
          }
          return of([response])
        }),
        map((v) => v.map((v) => v.data).flat())
      )

    return await lastValueFrom(request)
  }

  public executeRequest(request: Request): Observable<any> {
    if (request.method === 'post' || request.method === 'put') {
      return this.http[request.method.toLowerCase()](request.url, request.body, {
        headers: request.headers,
      })
    } else {
      return this.http[request.method.toLowerCase()](request.url, {
        headers: request.headers,
      })
    }
  }

  private value(v: unknown): string {
    if (typeof v === 'number') {
      return String(v)
    } else {
      return `"${v}"`
    }
  }
}
