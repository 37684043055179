import { DateFormatV2 } from '@agroone/dates'

import { IsDateValidate } from '@agroone/helpers'
import { IsInt, IsOptional, IsString } from 'class-validator'

export class SetHarvestDateDto {
  @IsString()
  @IsDateValidate(DateFormatV2.SIMPLE)
  yesterdayDate!: string

  @IsInt()
  @IsOptional()
  replacingOfferId?: number
}
