import { UserDto, UserPermissions } from '@agroone/entities'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { LoggerService, SharedPermissionService, SharedUserService, ToastService } from '../services'

export abstract class SharedUserGuard implements CanActivate {
  constructor(
    protected readonly logger: LoggerService,
    protected readonly toastService: ToastService,
    protected readonly translate: TranslateService,
    protected readonly userService: SharedUserService,
    protected readonly permissionService: SharedPermissionService
  ) {}

  /**
   * Validates that a user exist and checks the current user permissions
   */
  canActivate(next: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean {
    const user: UserDto = this.userService.currentUser
    if (!user) {
      this.logger.warn('Access denied - User not authenticated')
      return false
    }

    // Get the required permissions from the route data
    const requiredPermissions: UserPermissions[] = next.data.permissions || []

    if (requiredPermissions?.length === 0) {
      return true
    }

    const hasAccess: boolean = this.permissionService.isGranted(requiredPermissions)
    if (!hasAccess) {
      this.logger.warn(`Access denied - Permission(s) ${requiredPermissions.join(', ')} required`)
      this.toastService.showToastWarning(this.translate.instant('PERMISSION.ACCESS_DENIED'))
    }
    return hasAccess
  }
}
