import { addHours, addMinutes, addSeconds, parseISO, toDate } from 'date-fns'

/**
 * Return new date from the given one, adding hours, minutes, seconds;
 */
export function addTimeToDate(date: Date, hours: number = 0, minutes: number = 0, seconds: number = 0): Date {
  return addSeconds(addMinutes(addHours(date, hours), minutes), seconds)
}

export const parseDate = (date: string | Date): Date => convertToDate(date)

const convertToDate = (date: string | number | Date): Date => {
  if (typeof date === 'string') {
    return parseISO(date)
  }

  return toDate(date)
}
