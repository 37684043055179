import { Injectable } from '@angular/core'
import { environment } from '@front-app-environments/environment'
import { HttpClient } from '@angular/common/http'
import { MsSqlPaginatedData, AgropilotRole, User } from '@agroone/entities'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Technician } from 'libs/entities/src/lib/dtos/technician'

export interface UpdateUser {
  lastname?: string
  firstname?: string
  roles?: AgropilotRole[]
  regionName?: string
}

@Injectable({
  providedIn: 'root',
})
export class UserNetworkService {
  constructor(private http: HttpClient) {}

  getUser(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}${environment.users}/me`)
  }

  get(): Observable<User[]> {
    return this.http.get<MsSqlPaginatedData<User>>(`${environment.apiUrl}${environment.users}`).pipe(map((v) => v.data))
  }

  getTechnicians(): Observable<Technician[]> {
    return this.http.get<Technician[]>(`${environment.apiUrl}${environment.technicians}`)
  }
}
