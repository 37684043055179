import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator'
import { Language } from './language'

export class CsrConstant {
  id: number
  reference: number
  slug: string
  translations: CsrConstantTranslation[]
  tooltip?: string
  question?: string
}

export interface CsrConstantGroup {
  slug: string
  csrConstantId: number
  question: string
  tooltip: string
  translations?: CsrConstantTranslation[]
}

export interface CsrConstantGroupedByTheme {
  [key: string]: CsrConstant[]
}

interface CsrConstantTranslation {
  languageId: number
  language: Language
  question: string
  tooltip: string
  id: number
}

export interface CsrConstantRequest {
  id?: number[]
  csrConstantId: number
  languageId: number
  name: string
  question: string
  tooltip: string
  reference: number
  slug: string
}

export class SaveCsrConstant {
  @IsInt()
  @IsPositive()
  @IsOptional()
  id?: number

  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  csrConstantId: number

  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  languageId: number

  @IsString()
  @IsNotEmpty()
  question: string

  @IsString()
  @IsOptional()
  tooltip?: string
}
