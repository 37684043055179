export const cropTypes: Record<string, string> = {
  PEAS: 'peas',
  CORN: 'corn',
  SWEET_CORN: 'sweet corn',
  BEANS: 'beans',
  SHELLED_BEANS: 'shelled beans',
  LIMA_BEANS: 'lima beans',
}

export type CropType = (typeof cropTypes)[keyof typeof cropTypes]
