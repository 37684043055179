import { Injectable } from '@angular/core'
import { environment } from '@front-app-environments/environment'
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { catchError, map, tap } from 'rxjs/operators'
import { HarvestOfferPriorisation, IFilters, Offer, PatchOfferDto, RangeFilter } from '@agroone/entities'
import { parseDateFilter } from '@agropilot/app/core/library/date-utility'
import { addDays, isWithinInterval } from '@agropilot/app/core/library/date'
import { NetworkService } from '@agroone-front/shared'
import { OfferFormDto } from '@agropilot/app/features/crop/dtos/offer-form.dto'

export class inMemoryFilters {
  forecastHarvestDate?: RangeFilter

  constructor(filter: IFilters) {
    this.forecastHarvestDate =
      filter?.forecastHarvestDate?.start && filter?.forecastHarvestDate?.end ? filter.forecastHarvestDate : null
  }
}

/**
 * @deprecated use OfferService V2
 */
@Injectable({
  providedIn: 'root',
})
export class HarvestOfferService {
  public offers: Offer[]

  public offersWithoutMaturitySubject: BehaviorSubject<Offer[]> = new BehaviorSubject([])
  public offersWithoutMaturity$: Observable<Offer[]> = this.offersWithoutMaturitySubject.asObservable()

  private inMemoryFilter: inMemoryFilters
  private offersUrl = `${environment.apiUrl}${environment.offers}`

  constructor(private http: HttpClient, private networkService: NetworkService) {}

  public get(id: number): Observable<Offer> {
    return this.http.get<Offer>(`${this.offersUrl}/${id}`).pipe(
      map((offer: Offer) => new Offer(offer)),
      catchError((error) => {
        const offer = this.offers ? this.offers.find((offerItem) => id === offerItem.id) : null
        if (offer) {
          return of(offer)
        } else {
          return throwError(error)
        }
      })
    )
  }

  public getAllOfferQualitiesForRegion(region: string): Observable<Pick<Offer, 'quality'>[]> {
    return this.http.get<Pick<Offer, 'quality'>[]>(`${this.offersUrl}/qualities`, {
      params: { region },
    })
  }

  public getAll(filter?: string, onlyOpenCrops: boolean = false): Observable<Offer[]> {
    return from(
      this.networkService.getAllFromPaginated<Offer>(`${this.offersUrl}${filter ? filter : ''}`, {
        params: { pageLength: 300, onlyOpenCrops },
      })
    ).pipe(
      map((offers) => {
        this.offersWithoutMaturitySubject.next(offers)
        if (offers) {
          if (this.inMemoryFilter?.forecastHarvestDate) {
            return offers
              .map((o) => new Offer(o))
              .filter((o: Offer) =>
                isWithinInterval(
                  o.forecastHarvestDate,
                  parseDateFilter(this.inMemoryFilter.forecastHarvestDate.start),
                  parseDateFilter(addDays(this.inMemoryFilter.forecastHarvestDate.end, 1))
                )
              )
          }
          return offers.map((o) => new Offer(o))
        }
      }),
      tap((offers: Offer[]) => {
        this.offers = offers
      })
    )
  }

  public filterParser(filters: IFilters, includeExpiredOffers: boolean = false): string {
    let dbFilter: string = ''
    this.inMemoryFilter = new inMemoryFilters(filters)

    dbFilter += filters.region ? `region="${encodeURIComponent(filters.region)}",` : ''

    dbFilter += filters.region ? `cropType="${filters.cropType}",` : ''

    dbFilter +=
      filters.plantedDate?.start && filters.plantedDate?.end
        ? `plantedDate BETWEEN "${parseDateFilter(filters.plantedDate.start)}" AND "${parseDateFilter(
            addDays(filters.plantedDate.end, 1)
          )}",`
        : ''

    dbFilter +=
      filters.floweringDate?.start && filters.floweringDate?.end
        ? `floweringDate BETWEEN "${parseDateFilter(filters.floweringDate.start)}" AND "${parseDateFilter(
            addDays(filters.floweringDate.end, 1)
          )}",`
        : ''

    dbFilter +=
      filters.forecastHarvestDate?.start && filters.forecastHarvestDate?.end
        ? `forecastHarvestDate BETWEEN "${parseDateFilter(filters.forecastHarvestDate.start)}" AND "${parseDateFilter(
            addDays(filters.forecastHarvestDate.end, 1)
          )}",`
        : ''

    if (filters.varieties?.length) {
      dbFilter += filters.varieties?.length ? `varietyName IN ("${filters.varieties?.join('","')}"),` : ''
    }

    if (filters.technicians?.length) {
      const technicianFilterParser: string[] = filters.technicians.map((t) => encodeURIComponent(t))

      dbFilter += technicianFilterParser.length ? `technician IN ("${technicianFilterParser?.join('","')}"),` : ''
    }

    const organicFilter: string = filters.type ? '&organic=true' : ''

    return dbFilter
      ? `?filter=${dbFilter.slice(0, -1)},${includeExpiredOffers ? '' : 'endDate NOTEXISTS'}${organicFilter}`
      : `?filter=endDate NOTEXISTS${organicFilter}`
  }

  public getPriorisation(filter?: string): Observable<HarvestOfferPriorisation[]> {
    return this.http.get<HarvestOfferPriorisation[]>(`${this.offersUrl}/priorisation${filter ? filter : ''}`)
  }

  public post(offer: OfferFormDto): Observable<Offer> {
    return this.http.post<Offer>(this.offersUrl, offer)
  }

  public update(offer: OfferFormDto): Observable<Offer> {
    return this.http.put<Offer>(this.offersUrl, offer)
  }

  public patch(offerId: number, offer: PatchOfferDto): Observable<Offer> {
    return this.http.patch<Offer>(`${this.offersUrl}/${offerId}`, offer)
  }

  public updateDemandSampleDates(dates: string[], offerId: number): Observable<string[]> {
    return this.http.put<string[]>(`${this.offersUrl}/demand-samples/${offerId}`, { dates })
  }

  public delete(id: number): Observable<Offer> {
    return this.http.delete<Offer>(`${this.offersUrl}/${id}`)
  }

  public resetPriorisation(body: { date?: string; id?: string }) {
    return this.http.patch(`${this.offersUrl}/reset`, body)
  }
}
