import { Type } from 'class-transformer'
import { IsNumber, IsOptional, IsString } from 'class-validator'

export class SaveCsrFormResponse {
  @IsNumber()
  @Type(() => Number)
  constantId: number

  @IsString()
  @Type(() => String)
  value: string

  @IsString()
  type: 'string' | 'number' | 'boolean' | 'json'

  @IsString()
  @IsOptional()
  unit?: string

  @IsOptional()
  @IsString()
  metadata?: string
}
