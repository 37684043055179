import 'reflect-metadata'

import { plainToInstance, Type } from 'class-transformer'
import {
  ArrayMaxSize,
  ArrayNotEmpty,
  IsArray,
  IsBoolean,
  IsEnum,
  IsIn,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  Max,
  Min,
  Validate,
  ValidateNested,
} from 'class-validator'
import { SaveLocation } from './save-location'
import {
  IsDateValidate,
  IsDecimalNumber,
  IsISO8601WithTimezone,
  IsLaterThan,
  RequireProperty,
  UniquePropertyInArray,
} from '@agroone/helpers'
import { SupplierType } from '../enums/supplier-type.enum'
import { RateUnits } from '../enums/rate-units.enum'
import { SaveReporting } from './save-reporting'
import { SaveNotification } from './save-notification'
import { SaveForeignMaterial } from './save-foreign-material'
import { DateFormatV2 } from '@agroone/dates'

export class SaveRecommendationFoodSafety {
  @IsOptional()
  @ValidateNested()
  @Type(() => SaveLocation)
  location: SaveLocation

  @IsNumber()
  @Validate(IsDecimalNumber, [1])
  @IsOptional()
  @Min(0)
  @RequireProperty('bufferZoneUnit')
  bufferZone: number

  @IsString()
  @IsIn(['m', 'ft', 'ac', 'ha'])
  @IsOptional()
  @RequireProperty('bufferZone')
  bufferZoneUnit: string
}

export class SaveRecommendationIrrigationNutrient {
  @IsNumber()
  @IsNotEmpty()
  nutrientId: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @Max(100)
  @IsNotEmpty()
  value: number
}

// @deprecated
export class SaveRecommendationIrrigationNutrients {
  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationIrrigationNutrient)
  micro: SaveRecommendationIrrigationNutrient[]

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationIrrigationNutrient)
  macro: SaveRecommendationIrrigationNutrient[]
}

export class SaveRecommendationIrrigation {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  startingDate: string

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  @IsLaterThan('startingDate')
  endingDate: string

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @RequireProperty('areaUnit')
  area: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @RequireProperty('amountUnit')
  amount: number

  @IsString()
  @RequireProperty('area')
  @IsNotEmpty()
  areaUnit: string

  @IsString()
  @RequireProperty('amount')
  @IsNotEmpty()
  amountUnit: string

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationIrrigationNutrient)
  nutrients: SaveRecommendationIrrigationNutrient[]
}

export class SaveRecommendationPlanting {
  @IsNumber()
  @IsNotEmpty()
  varietyId: number

  @IsNumber()
  @Min(0)
  @RequireProperty('seedingRateUnit')
  @IsNotEmpty()
  seedingRate: number

  @IsString()
  @RequireProperty('seedingRate')
  @IsNotEmpty()
  seedingRateUnit: string

  @IsNumber()
  @Min(0)
  @RequireProperty('seedingAreaUnit')
  @IsNotEmpty()
  seedingArea: number

  @IsString()
  @RequireProperty('seedingArea')
  @IsNotEmpty()
  seedingAreaUnit: string

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  targetDate: string

  @IsString()
  @IsOptional()
  lotNumber?: string

  @IsNumber()
  @IsOptional()
  @RequireProperty('depthOfPlantingUnits')
  depthOfPlanting?: number

  @IsString()
  @IsOptional()
  @RequireProperty('depthOfPlanting')
  depthOfPlantingUnits?: string

  @IsString()
  @IsOptional()
  fieldBuffer?: string

  @IsBoolean()
  @IsOptional()
  rolledAfterSeed?: boolean
}

export class SaveRecommendationMechanicalWeeding {
  @IsString()
  @IsNotEmpty()
  area: string

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  targetDate: string
}

export class SaveRecommendationOtherOperation {
  @IsString()
  @IsNotEmpty()
  operationType: string

  @IsString()
  @IsNotEmpty()
  area: string

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  targetDate: string

  @IsNumber()
  @IsOptional()
  supplierId?: number
}

export class SaveRecommendationPesticidesProduct {
  @IsNumber()
  @IsNotEmpty()
  pesticideId: number

  @IsString({ each: true })
  @IsOptional()
  @IsArray()
  activeIngredientsNames: string[]

  @IsString()
  @IsNotEmpty()
  identificationNumber: string

  @IsString()
  @IsNotEmpty()
  name: string

  @IsNumber()
  @Validate(IsDecimalNumber, [3])
  @Min(0)
  @RequireProperty('rateUnit')
  @IsNotEmpty()
  rate: number

  @IsString()
  @IsEnum(RateUnits)
  @RequireProperty('rate')
  @IsNotEmpty()
  rateUnit: string

  @IsString()
  @RequireProperty('rate')
  @IsNotEmpty()
  rateAreaUnit: string

  @IsNumber()
  @IsNotEmpty()
  area: number

  @IsString()
  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  targetDate: string

  @IsString()
  @IsOptional()
  alternativeSolution?: string

  @IsString()
  @IsOptional()
  note?: string
}

export class SaveRecommendationSupplier {
  @IsNumber()
  @IsNotEmpty()
  id: number

  @IsString()
  @IsNotEmpty()
  name: string

  @IsString()
  @IsNotEmpty()
  type: SupplierType
}

export class SaveRecommendationPesticide {
  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @IsNotEmpty()
  waterAmount: number

  @IsString()
  @IsIn(['l', 'ml', 'gal'])
  @RequireProperty('waterAreaUnit')
  @IsNotEmpty()
  waterUnit: string

  @IsString()
  @RequireProperty('waterUnit')
  @IsNotEmpty()
  waterAreaUnit: string

  @IsArray()
  @ValidateNested()
  @Type(() => SaveRecommendationPesticidesProduct)
  @ArrayNotEmpty()
  @ArrayMaxSize(4)
  products: SaveRecommendationPesticidesProduct[]

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  completedDate?: string

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationSupplier)
  supplier?: SaveRecommendationSupplier

  @IsNumber()
  @IsOptional()
  supplierId?: number
}

export class SaveRecommendationCrop {
  @IsNumber()
  @IsNotEmpty()
  id: number

  @IsString()
  @IsNotEmpty()
  name: string

  @IsString()
  @IsOptional()
  shortName: string

  @IsString()
  @IsOptional()
  region?: string
}

export class SaveRecommender {
  language: string
  defaultDateFormat: string
  regionName?: string
  firstname?: string
  lastname?: string
  phoneNumber?: string
  phytoCertification?: string
}

export class SaveRecommendation extends SaveReporting {
  public static override build(raw: any) {
    return plainToInstance(SaveRecommendation, raw)
  }

  @IsNumber()
  @IsOptional()
  id: number

  @IsNotEmpty()
  @IsDateValidate(DateFormatV2.SIMPLE)
  date: string

  @IsString()
  @IsNotEmpty()
  recommendedBy: string

  @IsString()
  @IsNotEmpty()
  type: string

  @IsString()
  @IsNotEmpty()
  subType: string

  @IsString()
  @IsOptional()
  note?: string

  @IsString()
  @IsOptional()
  signature?: string

  @IsBoolean()
  @IsOptional()
  sendSMS?: boolean

  @IsBoolean()
  @IsOptional()
  sendEmail?: boolean

  @IsArray()
  @ArrayNotEmpty()
  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationCrop)
  crops?: SaveRecommendationCrop[]

  @IsString()
  @IsNotEmpty()
  region: string

  @IsArray()
  @IsNumber({}, { each: true })
  @IsOptional()
  scoutings?: number[]

  @IsOptional()
  @UniquePropertyInArray('contactType')
  @ValidateNested()
  @Type(() => SaveNotification)
  notifications?: SaveNotification[]

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationFoodSafety)
  foodSafety?: SaveRecommendationFoodSafety

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationOtherOperation)
  otherOperation?: SaveRecommendationOtherOperation

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationIrrigation)
  irrigation?: SaveRecommendationIrrigation

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationMechanicalWeeding)
  mechanicalWeeding?: SaveRecommendationMechanicalWeeding

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationPesticide)
  pesticides?: SaveRecommendationPesticide

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationPlanting)
  planting?: SaveRecommendationPlanting

  @IsNumber()
  @IsOptional()
  growerId?: number

  @IsArray()
  @ArrayMaxSize(3)
  @IsOptional()
  picturesPath?: string[]

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveRecommendationPesticide)
  pesticide?: SaveRecommendationPesticide

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveForeignMaterial)
  foreignMaterial?: SaveForeignMaterial
}
