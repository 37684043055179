import { FrontAppEnvironment } from '../entities/src/lib/dtos/environment'
import packageInfo from '../../package.json'
import { Resources } from '../entities/src/lib/dtos/resources'

const resources: Resources = {
  activities: '/activities',
  agreo: '/agreo',
  alerts: '/alerts',
  analysisTypes: '/analysis-types',
  alertSettings: '/alert-settings',
  anomalies: '/anomalies',
  cache: '/cache',
  campaigns: '/campaigns',
  comments: '/comments',
  constants: '/constants',
  countries: '/countries',
  crops: '/crops',
  croptypeForecasts: '/croptype-forecasts',
  croptypes: '/croptypes',
  csr: '/csr',
  csrConstants: '/csr-constants',
  dashboard: '/dashboard',
  directions: '/directions',
  factories: '/factories',
  fields: '/fields',
  geolocation: '/geolocation',
  growerProductionAreas: '/grower-production-areas',
  growers: '/growers',
  harvestTeams: '/harvest-teams',
  hypothesis: '/hypothesis',
  import: '/import',
  interventions: '/interventions',
  laboratories: '/laboratories',
  languages: '/languages',
  lots: '/lots',
  notifications: '/notifications',
  nutrients: '/nutrients',
  offers: '/offers',
  pesticides: '/pesticides',
  planifications: '/planifications',
  recommendations: '/recommendations',
  regions: '/regions',
  samples: '/samples',
  scoutings: '/scoutings',
  sectors: '/sectors',
  settings: '/settings',
  sowingPlannings: '/sowing-plannings',
  specifications: '/specifications',
  stocks: '/stocks',
  suppliers: '/suppliers',
  technicians: '/technicians',
  tpatasks: '/tpa-tasks',
  trucks: '/trucks',
  users: '/users',
  utils: '/utils',
  varieties: '/varieties',
  virtualCrops: '/virtual-crops',
  worklists: '/worklists',
  growerContracts: '/grower-contracts',
}

export const environment: FrontAppEnvironment = {
  ...resources,
  agrooneUrl: 'https://agroone.bonduelle.com',
  apiUrl: 'https://apiv2.cldprd.bonduelle.com',
  cognitoClientId: '73cblp0ndib9adfof0tep26cbv',
  cognitoDomain: 'https://ssoconnect.bnd-services.com',
  dynatraceScriptSrc: 'https://js-cdn.dynatrace.com/jstag/1623d230d17/bf75358etl/d75b5c164b259234_complete.js',
  envName: 'prd',
  agropilotUrl: 'https://agropilot.bonduelle.com/',
  headerColorEnd: '#c9ea63',
  headerColorStart: '#a4ca2b',
  int: false,
  local: false,
  logLevel: 'ERROR',
  mapboxDirectionsApi: 'https://api.mapbox.com/directions/v5/mapbox/',
  masterdataUrl: 'https://masterdata.bonduelle.com/',
  production: true,
  qlf: false,
  serverLogLevel: 'WARN',
  version: packageInfo.version,
  websocket: 'wss://ws.apiv2.cldprd.bonduelle.com',
}
