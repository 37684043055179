import { CommonModule } from '@angular/common'
import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, Self, TemplateRef } from '@angular/core'
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms'
import { NgOption, NgSelectModule } from '@ng-select/ng-select'
import { TranslateModule } from '@ngx-translate/core'
import { SharedSelectConfig } from './shared-select.model'

@Component({
  selector: 'app-shared-select',
  templateUrl: './shared-select.component.html',
  standalone: true,
  imports: [NgSelectModule, FormsModule, TranslateModule, CommonModule],
})
export class SharedSelectComponent implements ControlValueAccessor {
  public defaultConfig: SharedSelectConfig = {
    multiple: false,
    searchable: false,
    readonly: false,
    bindLabel: 'label',
    bindValue: 'value',
    placeholder: null,
    useBindValue: true,
    virtualScroll: false,
    closeOnSelect: true,
    clearable: true,
  }

  public mergedConfig: SharedSelectConfig = this.defaultConfig

  @Input() set config(value: SharedSelectConfig) {
    this.mergedConfig = { ...this.defaultConfig, ...value }
  }
  @Input() items: NgOption[] = []
  @Input() optionTemplate!: TemplateRef<any>
  @Input() labelTemplate!: TemplateRef<any>
  @Input() trackByFn: (index: number, item: NgOption) => any
  @Input() compareWith: (o1: any, o2: any) => boolean

  @Output() selectionChange: EventEmitter<NgOption | null> = new EventEmitter<NgOption | null>()
  @Output() onClear: EventEmitter<null> = new EventEmitter<null>()

  selectedValue!: NgOption | null
  protected get control(): NgControl | undefined {
    return this._ngControl || undefined
  }

  constructor(private readonly _cd: ChangeDetectorRef, @Optional() @Self() private readonly _ngControl?: NgControl) {
    if (_ngControl) {
      _ngControl.valueAccessor = this
    }
  }

  writeValue(value: NgOption): void {
    if (this.selectedValue == value) {
      return
    }
    this.selectedValue = value
    this.selectionChange.emit(this.selectedValue)
    this._cd.markForCheck()
  }

  registerOnChange(onChange: (value: NgOption | null) => void): void {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = () => {
      onTouched()
    }
  }

  onChange: (value: NgOption | null) => void = () => {}

  onTouched: () => void = () => {}

  onSelectionChange(option: NgOption): void {
    if (!option) {
      this._resetSelection()
      return
    }
    this.selectedValue = this.mergedConfig.useBindValue ? this._extractBindValue(option) : option

    this._propagateSelectionChange()
  }

  isItemSelected(option: NgOption): boolean {
    if (!this.selectedValue || !this.mergedConfig.multiple) return false
    const selectedItem = this.mergedConfig.useBindValue ? option[this.mergedConfig.bindValue] : option
    return (this.selectedValue as NgOption[]).some((selected) => selected === selectedItem)
  }

  private _resetSelection(): void {
    this.selectedValue = null
    this._propagateSelectionChange()
  }

  private _extractBindValue(option: NgOption): any {
    if (!option) {
      return
    }
    const bindKey = this.mergedConfig?.bindValue

    return this.mergedConfig?.multiple ? option?.map((item: any) => item[bindKey]) : option[bindKey]
  }

  private _propagateSelectionChange(): void {
    this.onChange(this.selectedValue)
    this.selectionChange.emit(this.selectedValue)
    this.onTouched()
  }

  onClearSelect(): void {
    this.selectedValue = null
    this.selectionChange.emit(this.selectedValue)
    this.onClear.emit(null)
  }
}
