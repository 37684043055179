import { plainToInstance, Type } from 'class-transformer'
import {
  IsBoolean,
  IsDateString,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  Length,
  Validate,
  ValidateNested,
} from 'class-validator'

import { IsISO8601WithTimezone } from '@agroone/helpers'
import { SaveReportingV2 } from './save-reporting'

export class SaveOffer extends SaveReportingV2 {
  public static override build(raw: any) {
    return plainToInstance(SaveOffer, raw)
  }

  @IsInt()
  @IsPositive()
  @IsOptional()
  id?: number

  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  cropId!: number

  @IsString()
  @IsOptional()
  region?: string

  @IsInt()
  @IsPositive()
  @IsOptional()
  priority?: number

  @IsString()
  @IsOptional()
  typeOfCulture?: string

  @IsString()
  @IsOptional()
  quality?: string

  @IsString()
  @IsOptional()
  phyto?: string

  @IsString()
  @IsOptional()
  phytoFilterDate?: string

  @IsString()
  @IsOptional()
  observation?: string

  @IsOptional()
  @Length(10)
  @IsDateString()
  forecastHarvestDate?: string

  @IsNumber()
  @IsNotEmpty()
  areaToHarvest: number

  @IsString()
  @IsNotEmpty()
  areaUnit: string

  @IsNumber()
  @IsOptional()
  yield?: number

  @IsString()
  @IsOptional()
  yieldUnit?: string

  @IsNumber()
  @IsOptional()
  quantityToHarvest?: number

  @IsString()
  @IsOptional()
  quantityUnit?: string

  @IsBoolean()
  @IsNotEmpty()
  bypass: boolean

  @IsInt()
  @IsPositive()
  @IsOptional()
  status?: number

  @IsString()
  @IsOptional()
  groupCode?: string

  @Length(10)
  @IsDateString()
  @IsOptional()
  demandSampleDate?: string

  @Length(10)
  @IsDateString()
  @IsOptional()
  confirmedHarvestDate?: string

  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  lastCheckOutSiteDate?: string

  @Validate(IsISO8601WithTimezone)
  @IsOptional()
  endDate?: string

  @IsNumber()
  @IsOptional()
  durationLots?: number

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveOfferRisk)
  observationRisks?: SaveOfferRisk[]

  @IsString()
  @IsOptional()
  otherRisk?: string

  @IsNumber()
  @IsOptional()
  harvestTeamId?: number

  @IsNumber()
  @IsOptional()
  cadence?: number | null

  @IsNumber()
  @IsOptional()
  speed?: number | null

  @IsNumber()
  @IsOptional()
  replaceOfferId?: number | null

  @IsNumber()
  @IsOptional()
  droppedAfterOfferId?: number | null

  @IsNumber()
  @IsOptional()
  factoryId?: number | null
}

export class SaveOfferRisk {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsOptional()
  scoutingId?: number

  @IsString()
  @IsNotEmpty()
  scoutingSubType?: string

  @IsString()
  @IsNotEmpty()
  name?: string
}
