import { Component, Inject } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LoadingService } from './core/services/loading/loading.service'
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { NetworkingService } from './core/networking/services/networking.service'
import { WebSocketsService } from './core/services/websockets/websockets.service'
import { environment } from '@front-app-environments/environment'
import { DOCUMENT } from '@angular/common'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { VersionService } from '@agroone-front/shared'

// eslint-disable-next-line
declare var dT_
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private networkService: NetworkingService,
    private webSocketsService: WebSocketsService,
    private versionService: VersionService,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(this.http, HttpHeaders)
    }

    this.versionService.checkForUpdates()

    this.networkService.connection.subscribe((isConnected) => {
      if (!environment.local) {
        if (!isConnected) {
          this.webSocketsService.disconnect()
        } else if (!webSocketsService.isConnect) {
          this.webSocketsService.connect()
        }
      }
    })

    // Init icons
    this.matIconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icon-set.svg'))

    this.translate.get('LOADING.DEFAULT').subscribe((value) => {
      this.loadingService.setLoading({
        message: value,
      })
    })

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingService.setLoading({ loading: true })
      }
      if (event instanceof NavigationCancel || event instanceof NavigationEnd || event instanceof NavigationError) {
        this.loadingService.setLoading({ loading: false })
      }
    })
    this.loadExternalScript(environment.dynatraceScriptSrc)
  }

  private loadExternalScript(scriptUrl: string): void {
    if (!scriptUrl) {
      return
    }

    const scriptElement: HTMLScriptElement = document.createElement('script')
    scriptElement.src = scriptUrl
    scriptElement.type = 'text/javascript'
    document.body.appendChild(scriptElement)
  }
}
