import { NgIf } from '@angular/common'
import { Component } from '@angular/core'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'

import { TranslateModule } from '@ngx-translate/core'
@Component({
  selector: 'app-new-version-popup',
  templateUrl: './new-version-popup.component.html',
  styleUrls: ['./new-version-popup.component.sass'],
  imports: [MatDialogModule, MatProgressSpinnerModule, MatButtonModule, TranslateModule, NgIf],
  standalone: true,
})
export class NewVersionPopupComponent {
  public isUpdateInProgress: boolean = false

  reload() {
    this.isUpdateInProgress = true
    window.location.reload()
  }
}
