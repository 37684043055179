import { SharedUserService } from '@agroone-front/shared'
import { IFilters } from '@agroone/entities'
import { Injectable } from '@angular/core'
import { Technician } from 'libs/entities/src/lib/dtos/technician'
import { SelectItem } from 'primeng/api'
import { BehaviorSubject, Observable } from 'rxjs'

export const defaultFilters: IFilters = {
  cropType: null,
  region: null,
  technicians: null,
  varieties: null,
  specification: null,
  harvestGroupementKey: null,
  type: null,
  forecastHarvestDate: null,
  floweringDate: null,
  plantedDate: null,
  onlyOpenCrops: false,
  offerStatuses: [],
}

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  public filterSubject: BehaviorSubject<IFilters> = new BehaviorSubject(defaultFilters)
  public filters$: Observable<IFilters> = this.filterSubject.asObservable()
  get filters(): IFilters {
    return this.filterSubject.getValue()
  }

  constructor(private userService: SharedUserService) {}

  init() {
    // initialize observable
    if (localStorage.getItem('filterInfos')) {
      const data: IFilters = this.getStoredFilters()
      this.filterSubject.next(data)
    } else {
      defaultFilters.region = this.userService.currentUser.regionName
      this.saveToLocalStorage('filterInfos', defaultFilters)
    }
  }

  public setFilter(value: IFilters): void {
    this.filterSubject.next(value)

    const filterLocal: IFilters = this.getStoredFilters()

    // sort sur la colonne + ACS / DESC
    const filter: IFilters = {
      cropType: value.cropType || filterLocal.cropType || null,
      region: value.region ?? filterLocal.region ?? null,
      technicians: value.technicians ?? [],
      varieties: value.varieties ?? [],
      specification: value.specification ?? null,
      harvestGroupementKey: value.harvestGroupementKey ?? null,
      type: value.type ?? null,
    }

    this.saveToLocalStorage('filterInfos', filter)
  }

  /**
   * @deprecated remove after migration
   */
  public techniciansByRegion(technicians: Technician[], region: string): SelectItem[] {
    const techniciansValue: SelectItem[] = technicians
      ?.filter((technician) => technician.availableRegions.find((availableRegion) => availableRegion === region))
      ?.map((technician) => ({
        label: technician.email?.split('@')?.[0],
        value: technician.email,
      }))
    return techniciansValue.sort((x, y) => x.label.localeCompare(y.label))
  }

  private saveToLocalStorage(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data))
    } catch (error) {
      console.error(`Failed to save ${key} to localStorage:`, error)
    }
  }

  public getStoredFilters(): IFilters | null {
    const data: any = localStorage.getItem('filterInfos')
    try {
      return data ? JSON.parse(data) : null
    } catch {
      console.warn('Invalid filterInfos data in localStorage')
      return null
    }
  }
}

export { IFilters }
