import { DateFormatV2 } from '@agroone/dates'
import { IsDateValidate } from '@agroone/helpers'
import { IsInt, IsOptional, IsPositive, IsString } from 'class-validator'

export class UpdateHarvestTeamDto {
  @IsString()
  @IsDateValidate(DateFormatV2.SIMPLE)
  yesterdayDate!: string

  @IsInt()
  @IsOptional()
  previousOfferId?: number

  @IsInt()
  @IsPositive()
  @IsOptional()
  harvestTeamId?: number
}
