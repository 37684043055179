import { plainToInstance } from 'class-transformer'
import { IsEnum, IsInt, IsNotEmpty, IsNumber, IsOptional, IsPositive } from 'class-validator'

import { DateFormatV2 } from '@agroone/dates'
import { IsDateValidate } from '@agroone/helpers'
import { CostUnit } from '../enums/cost-unit.enum'
import { SaveReporting } from './save-reporting'
export class SaveGrowerContract extends SaveReporting {
  public static override build(raw: any) {
    return plainToInstance(SaveGrowerContract, raw)
  }

  @IsInt()
  @IsPositive()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsPositive()
  @IsNotEmpty()
  cost!: number

  @IsEnum(CostUnit)
  @IsNotEmpty()
  costUnit!: CostUnit

  @IsOptional()
  @IsDateValidate(DateFormatV2.SIMPLE)
  effectiveDate?: Date

  @IsNumber()
  @IsNotEmpty()
  campaignId!: number

  @IsNumber()
  @IsNotEmpty()
  cropTypeId!: number

  @IsNumber()
  @IsNotEmpty()
  growerId!: number
}
