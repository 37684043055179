import { environment } from '@front-app-environments/environment'
import { Injectable } from '@angular/core'
import { firstValueFrom, Observable } from 'rxjs'
import { NetworkingService } from '@agropilot/app/core/networking/services/networking.service'
import { mergeMap, tap } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { Location } from '@agroone/entities'
import { Direction } from '@agroone/entities'
import { isBefore, subMinutes } from '@agropilot/app/core/library/date'
import { HarvestDirection } from '@agroone/entities'

export class MapBoxToken {
  token: string
  expires: string
}

@Injectable({
  providedIn: 'root',
})
export class DirectionsNetworkService {
  private readonly routingProfile = 'driving'
  private temporaryToken: MapBoxToken = null
  private setToken = false

  get tokenHasExpired(): boolean {
    return this.temporaryToken ? isBefore(new Date(this.temporaryToken.expires), new Date()) : true
  }

  constructor(private network: NetworkingService) {}

  public retrieveToken(): Observable<MapBoxToken> {
    const url = `${environment.apiUrl}${environment.geolocation}/token`
    return this.network.get<MapBoxToken>(url).pipe(
      tap((result: MapBoxToken) => {
        this.temporaryToken = result
      })
    )
  }

  public getDirections(from: Location, to: Location, excluMotorway: boolean): Observable<any> {
    // Retrieve directions API
    // Required parameters: profile, coordinates
    // coordinates: A semicolon-separated list of {longitude},{latitude} coordinate pairs to visit in order.
    // There can be between two and 25 coordinates for most requests, or up to three coordinates for driving-traffic requests.

    const url =
      environment.mapboxDirectionsApi +
      this.routingProfile +
      `/${from.longitude},${from.latitude};${to.longitude},${to.latitude}`

    if (this.temporaryToken) {
      if (isBefore(subMinutes(new Date(this.temporaryToken.expires), 10), new Date()) && !this.setToken) {
        this.setToken = true
        firstValueFrom(this.retrieveToken()).finally(() => (this.setToken = false))
      }
    }

    if (this.temporaryToken && !this.tokenHasExpired) {
      let params = new HttpParams().append('access_token', this.temporaryToken.token)
      if (excluMotorway) {
        params = params.append('exclude', 'motorway')
      }
      return this.network.get<Direction>(url, { params })
    } else if (!this.temporaryToken || this.tokenHasExpired) {
      return this.retrieveToken().pipe(
        mergeMap(() => {
          let params = new HttpParams({}).append('access_token', this.temporaryToken.token)
          if (excluMotorway) {
            params = params.append('exclude', 'motorway')
          }
          return this.network.get(url, { params })
        })
      )
    }
  }

  public postDirection(body: HarvestDirection): Promise<any> {
    const url = `${environment.apiUrl}${environment.directions}`
    delete body.id
    if (!body.distance) {
      body.distance = 0.0
    }
    return this.network.post(url, body)
  }
}
