import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

export interface ILoadingParams {
  message?: string
  loading?: boolean
  animation?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private message: BehaviorSubject<string> = new BehaviorSubject(null)
  private status: BehaviorSubject<boolean> = new BehaviorSubject(false)
  private animated: BehaviorSubject<boolean> = new BehaviorSubject(true)

  get message$(): Observable<string> {
    return this.message.asObservable()
  }

  get status$(): Observable<boolean> {
    return this.status.asObservable()
  }

  get animated$(): Observable<boolean> {
    return this.animated.asObservable()
  }

  getLoadingStatus(): boolean {
    return this.status.value
  }

  setLoading(params: ILoadingParams = { message: null, loading: false, animation: false }) {
    const { message, loading, animation } = params
    if (typeof message !== 'undefined' && message !== null) {
      this.message.next(message)
    }
    if (typeof loading !== 'undefined') {
      this.status.next(loading)
    }
    if (typeof animation !== 'undefined') {
      this.animated.next(animation)
    }
  }

  toggleStatus(value?: boolean) {
    if (typeof value !== 'undefined') {
      this.status.next(value)
    } else {
      const current = this.status.value
      this.status.next(!current)
    }
  }
}
