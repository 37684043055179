import { SharedConstantService } from '@agroone-front/shared'
import {
  Constant,
  ConstantKeyTranslation,
  ConstantLite,
  Constants,
  ConstantsLite,
  Croptype,
  HarvestGroupementType,
  MsSqlPaginatedData,
  UserDto,
} from '@agroone/entities'
import { SharedUserService } from '@agroone-front/shared'
import { environment } from '@front-app-environments/environment'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, forkJoin, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'

@Injectable()
export class ConstantService extends SharedConstantService {
  private currentUserLanguage: string

  baseUrl: string = `${environment.apiUrl}${environment.constants}`

  constructor(protected override http: HttpClient, private userService: SharedUserService) {
    super(http)
  }

  override init() {
    this.currentUserLanguage = this.userService.currentUser?.language
    this.userService.user
      .pipe(
        mergeMap((user: UserDto) => (user ? this._getAll(user.language) : of([new ConstantsLite(), []]))),
        filter(Boolean),
        map(([constants, croptypes]: [ConstantsLite, Croptype[]]) => {
          for (const constantName of Object.keys(constants)) {
            if (typeof constants[constantName][0].priority === 'number') {
              constants[constantName] = constants[constantName].sort((a, b) => a.priority - b.priority)
            }
          }
          const mappedCroptypes: ConstantLite[] = []
          for (const croptype of croptypes) {
            mappedCroptypes.push({
              croptypeId: croptype.id,
              code: croptype.key,
              regions: croptype.regions,
              translation: croptype.translations?.[0]?.value,
            })
          }
          return [constants, mappedCroptypes]
        }),
        catchError(() => of([this.constants, []]))
      )
      .subscribe(([constants, croptypes]: [ConstantsLite, ConstantLite[]]) => {
        this.constants = constants
        this.croptypes = croptypes
        this._setHarvestGroupement(constants)
      })
  }

  private _setHarvestGroupement(constant: ConstantsLite) {
    if (constant?.harvestGroupementType?.length > 0) {
      this.harvestGroupementType = constant.harvestGroupementType.map(
        (harvestGroupementType) => new HarvestGroupementType(harvestGroupementType)
      )
    }
  }

  private _getAll(language: string): Observable<[ConstantsLite, Croptype[]]> {
    const constantsParams: HttpParams = new HttpParams().set('languageName', language)
    const constantsOptions: {
      params: HttpParams
    } = { params: constantsParams }

    const croptypesParams: HttpParams = new HttpParams().set('filter', `languageName="${language}"`)
    const croptypesOptions: {
      params: HttpParams
    } = { params: croptypesParams }

    const constants: Observable<ConstantsLite> = this.http
      .get<Constants>(this.baseUrl, constantsOptions)
      .pipe(map((constants: Constants) => this.constantsToConstantsLite(constants)))
    const croptypes: Observable<Croptype[]> = this.http
      .get<MsSqlPaginatedData<Croptype>>(`${environment.apiUrl}${environment.croptypes}`, croptypesOptions)
      .pipe(map((res: MsSqlPaginatedData<Croptype>) => res.data))

    return forkJoin([constants, croptypes])
  }

  protected override _constantToConstantLite(constant: Constant): ConstantLite[] {
    const constantLites: ConstantLite[] = []
    for (const [key, value] of Object.entries(constant.values)) {
      const cropTypes: string[] = value?.cropTypes?.map((r) => r.name)
      const translation: string = value?.translations?.find(
        (t: ConstantKeyTranslation) => t.language === this.currentUserLanguage
      )?.value
      const constantLite: ConstantLite = {
        code: key,
        cropTypes,
        translation,
        ...value,
      } as unknown as ConstantLite
      constantLites.push(constantLite)
    }
    return constantLites
  }
}
