import { DateFormatV2 } from '@agroone/dates'

import { IsDateValidate } from '@agroone/helpers'
import { IsString } from 'class-validator'

export class UnplanifyOfferDto {
  @IsString()
  @IsDateValidate(DateFormatV2.SIMPLE)
  yesterdayDate!: string
}
