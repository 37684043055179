import { Injectable, OnDestroy } from '@angular/core'
import { environment } from '@front-app-environments/environment'
import { NetworkingService } from '@agropilot/app/core/networking/services/networking.service'
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs'
import { tap } from 'rxjs/operators'
import {
  CroptypeColor,
  CroptypePlanning,
  CroptypePlanningSettings,
  Factory,
  FactoryConsumption,
  HarvestDirection,
  ISettingsConfig,
  TruckSpeed,
} from '@agroone/entities'
import { HttpResponse } from '@angular/common/http'
import { settings } from '@agropilot/env/constants'
import { OffersDisplayList } from '@agropilot/app/constants/offers-display-list'
import { RegionService } from '../region/region.service'

@Injectable({
  providedIn: 'root',
})
export class SettingsService implements OnDestroy {
  public settingsSubject: BehaviorSubject<ISettingsConfig> = new BehaviorSubject({
    trucks: [],
    destinations: [],
    stocks: [],
    croptypePlannings: [],
    factoryConsumptions: [],
    harvestTeams: [],
    directions: [],
    croptypeColors: [],
    offersDisplayList: [],
    truckSpeeds: [],
  })
  public settings$: Observable<ISettingsConfig> = this.settingsSubject.asObservable()

  public get croptypeColors(): CroptypeColor[] {
    return this.getValue().croptypeColors ?? []
  }

  public get offersDisplayList(): {
    controlCodes: string[]
    cropType: string
    region: string
  }[] {
    return OffersDisplayList
  }

  public get croptypePlannings(): CroptypePlanningSettings[] {
    return this.getValue().croptypePlannings ?? []
  }

  public get factoryConsumptions(): FactoryConsumption[] {
    return this.getValue().factoryConsumptions ?? []
  }

  public get destinations(): Factory[] {
    return this.getValue().destinations ?? []
  }

  public get directions(): HarvestDirection[] {
    return this.directionsSubject.getValue() ?? []
  }

  public get truckSpeeds(): TruckSpeed[] {
    return this.getValue().truckSpeeds ?? []
  }

  public directionsSubject: BehaviorSubject<HarvestDirection[]> = new BehaviorSubject(null)

  private destroyed: Subject<void> = new Subject()
  private readonly SETTINGS_URL = `${environment.apiUrl}${environment.settings}/`

  constructor(private network: NetworkingService, private regionService: RegionService) {}

  async init(): Promise<ISettingsConfig> {
    return firstValueFrom(this.get())
  }

  public getTruckSpeedByRegion(region: string): number {
    const regionId = this.regionService.currentRegions?.find((r) => r.name === region)?.id
    const truckSpeed: TruckSpeed = this.truckSpeeds ? this.truckSpeeds.find((t) => t.regionId === regionId) : undefined
    return truckSpeed ? truckSpeed.speed : settings.defaultTimes.truckSpeed
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }

  public getValue(): ISettingsConfig {
    return this.settingsSubject.getValue()
  }

  public setDirectionValue(value: HarvestDirection): void {
    const directions = this.directions
    if (
      !directions.find(
        (d) =>
          d.location_latitude_a === value.location_latitude_a &&
          d.location_longitude_a === value.location_longitude_a &&
          d.location_latitude_b === value.location_latitude_b &&
          d.location_longitude_b === value.location_longitude_b &&
          d.motorway === value.motorway
      )
    ) {
      directions.push(value)
      this.directionsSubject.next(directions)
    }
  }

  public saveCroptypePlanning(body: CroptypePlanning): Promise<HttpResponse<any>> {
    return this.network.put(`${this.SETTINGS_URL}croptype-plannings`, body)
  }

  public saveFactoryConsumption(body: FactoryConsumption): Promise<HttpResponse<any>> {
    return this.network.put(`${this.SETTINGS_URL}factory-consumptions`, body)
  }

  public get(): Observable<ISettingsConfig> {
    return (this.network.get(this.SETTINGS_URL) as Observable<ISettingsConfig>).pipe(
      tap((value: ISettingsConfig) => {
        this.settingsSubject.next(value)
        this.directionsSubject.next(value.directions)
      })
    )
  }
}
