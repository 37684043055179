import { Injectable } from '@angular/core'
import { PriorityColors } from '../offers/models/priority-colors'
import { Offer, StatusOffer } from '@agroone/entities'
import { differenceInDays } from 'date-fns'

@Injectable({
  providedIn: 'root',
})
export class PriorityService {
  private _colors: PriorityColors = {
    dayMinus2: '#ef7159',
    dayMinus1: '#FBC503',
    day: '#C5CF00',
    dayPlus1: '#67C6E8',
    dayPlus2: '#bb7ee8',
    dayPlus3: '#CCCCCC',
  }

  init() {
    this._setColors()
  }

  private _setColors() {
    document.documentElement.style.setProperty('--day-minus-two', this._colors.dayMinus2)
    document.documentElement.style.setProperty('--day-minus-one', this._colors.dayMinus1)
    document.documentElement.style.setProperty('--day', this._colors.day)
    document.documentElement.style.setProperty('--day-plus-one', this._colors.dayPlus1)
    document.documentElement.style.setProperty('--day-plus-two', this._colors.dayPlus2)
    document.documentElement.style.setProperty('--day-plus-three', this._colors.dayPlus3)
  }

  getPriorityColors() {
    return this._colors
  }

  getBackgroundColorForDate(date: Date): string {
    switch (differenceInDays(date, new Date().setHours(0, 0, 0, 0))) {
      case -1: {
        return this._colors.dayMinus1
      }
      case 0: {
        return this._colors.day
      }
      case 1: {
        return this._colors.dayPlus1
      }
      case 2: {
        return this._colors.dayPlus2
      }
      default: {
        if (differenceInDays(date, new Date().setHours(0, 0, 0, 0)) > 2) {
          return this._colors.dayPlus3
        } else {
          return this._colors.dayMinus2
        }
      }
    }
  }

  getBackgroundColorForOffer(offer: Offer): string {
    if (offer.status === StatusOffer.offerClose) {
      return '#636363'
    }
    const offerDate: Date = new Date(offer.forecastHarvestDate)
    offerDate.setHours(0, 0, 0, 0)
    return this.getBackgroundColorForDate(offerDate)
  }
}
